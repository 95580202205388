import { ref, computed } from "vue";
import useMock from "@/utils/payment/useMock";
import Data from "@/views/User/ApprovalProcess/ApprovalChain/RoleSetting/mock";
import { DataProps } from "@/views/User/ApprovalProcess/ApprovalChain/RoleSetting/type";
import * as API from "@/API/approvalChain"
import {getRoleDetail} from "@/API/approvalChain";
import {downloadFromStream} from "@/utils/payment/downloadFile";
import {message, Modal} from "ant-design-vue";
const useRoleSetting = () => {
  const isFinance = ref<boolean>(false)
  const columnNormal = [
    {
      title: "Approval Role",
      dataIndex: "name",
      fixed: 'left'
    },
    {
      title: "BU",
      dataIndex: "bu",
    },
    {
      title: "Title",
      dataIndex: "title",
      width: 300,
    },
    {
      title: "User Name/Effective From",
      dataIndex: "userInfo",
      slots: {customRender: 'users'},
      width: 400,
    },
    {
      title: "Operation",
      slots: {customRender: 'operation'},
      width: 120,
      align: 'center',
      fixed: 'right'
    },
  ];
  const columnFL = [
    {
      title: "Approval Role",
      dataIndex: "name",
    },
    {
      title: "Approval/Notification",
      dataIndex: "state",
      filters: [
        { text: 'Approval', value: 'Approval' },
        { text: 'Notification', value: 'Notification' },
      ],
      onFilter: (value: string, record: any) => {
        if (record.state === null) {return false}
        else {
          return record.state.indexOf(value) === 0
        }
      }
    },
    {
      title: "Title",
      dataIndex: "title",
      width: 300,
    },
    {
      title: "User Name/Effective From",
      dataIndex: "userInfo",
      slots: {customRender: 'users'},
      width: 400,
    },
    {
      title: "Operation",
      slots: {customRender: 'operation'},
      width: 120,
      align: 'center',
      fixed: 'right'
    },
  ];
  const column = computed(()=>{
    if (isFinance.value){
      return columnFL
    }
    else {
      return columnNormal
    }
  })
  const ress = ref();
  const roleList = computed(() => {
    return ress.value?.data;
  });
  const getRoleList = () => {
   /* useMock<DataProps>(Data).then((data) => {
      res.value = data;
    });*/
   API.getApprovalChainSetting('Normal').then((res: any)=>{
     console.log('resNormal=========',res)
     ress.value = res
   })
  };
  const getFinanceAndLeasingList = () => {
    console.log('isFinance==============',isFinance.value)
    API.getApprovalChainSetting('FL').then((res: any)=>{
      console.log('resFL=========',res)
      ress.value = res
    })
  }
  const getRoleDetail = (roleId: string,businessType: string) => {
    API.getRoleDetail(roleId,businessType).then((res: any)=>{
      console.log('ROLE详情结果=========',res)
      ress.value = res
    })
  }
  const deleteRole = (record: any,businessType: string) => {
     const userIds = [] as string[]
     const userRoleIds = [] as string[]
    if (record.userInfo){
      record.userInfo.forEach((item: any) => {
        userIds.push(item.userId)
        userRoleIds.push(item.userRoleId)
      })
    }
     const paramsDel = {
       roleId: record.roleId,
       userId: userIds,
       roleType: businessType,
       userRoleId: userRoleIds
     }
    console.log('删除参数============',paramsDel)
     API.deleteRoleDetail(paramsDel).then((res: any)=>{
       console.log('deleteResult====',res)
       if (res.code === '0'){
         if (businessType === 'FL'){
           message.success("Delete success");
           getFinanceAndLeasingList()
         }
         if (businessType === 'Normal'){
           message.success("Delete success");
           getRoleList()
         }
       }
       else {
         Modal.error({
           title: 'Tips',
           content: res.message
         })
       }
     })
  }
  const onDownloadFLConfirm = () =>{
    /*const payoutCodes = rowSelectionPayoutCode.selectedRowData.map((item) => item.payoutCode)
    API.downloadPayoutCodeFile(payoutCodes).then((res: unknown) => {
      downloadFromStream(res)
    })*/
  }
  const onDownloadNormalConfirm = () =>{
    /*const payoutCodes = rowSelectionPayoutCode.selectedRowData.map((item) => item.payoutCode)
    API.downloadPayoutCodeFile(payoutCodes).then((res: unknown) => {
      downloadFromStream(res)
    })*/
  }

  return {
    column,
    getRoleList,
    getFinanceAndLeasingList,
    roleList,
    isFinance,
    getRoleDetail,
    deleteRole,
    onDownloadFLConfirm,
    onDownloadNormalConfirm
  };
};
export default useRoleSetting;
