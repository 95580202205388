
import { defineComponent, computed, reactive, PropType, ref, watch, h } from 'vue';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import { DataProps } from '../../ApprovalProcess/ApprovalChain/RoleSetting/type';
import useRoleSetting from '@/hooks/approvalChian/useRoleSetting';
import * as API from '@/API/approvalChain';
import { message, Modal } from 'ant-design-vue';
import useModel from '@/hooks/useModel';
import moment, { Moment } from 'moment';
import { useStore } from 'vuex';
type TypeProp = 'add' | 'edit';
let timeout: any;
let currentValue = '';

function fetch(value: string, callback: any) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  function fake() {
    value = value.trim()
    if (!value) {
      return false;
    }
    API.getUserList(value).then((res: any) => {
      if (currentValue === value) {
        // const result = res
        // const data: any[] = [];
        // result.forEach((item: any) => {
        //   data.push({
        //     value: item.usrid,
        //     label: item.fulnm,
        //   });
        // });
        callback(res);
      }
    });
  }

  timeout = setTimeout(fake, 300);
}
export default defineComponent({
  name: 'App',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String as PropType<TypeProp>
    },

    data: {
      type: Object as any
    },
    businessType: {
      type: String,
      default: 'Normal'
    },
    random: {
      type: Number
    },
    isQuery: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const { state } = useStore();
    console.log('0000======', state.user.usrnm);
    const { getRoleDetail, getRoleList, getFinanceAndLeasingList } = useRoleSetting();
    // console.log('businessType====',props.businessType)
    const roleId = ref('');
    const formState = ref({
      approvalRole: '',
      buName: '',
      title: '',
      flState: ''
    });
    const userArr = ref([
      {
        userRoleId: '',
        userId: '',
        userName: '',
        effectiveFrom: moment(new Date()).format('YYYY-MM-DD'),
        email: state.user.email,
        userData: []
      }
    ]);
    const formRef = ref();
    const resetForm = () => {
      formRef.value.resetFields();
      formState.value.approvalRole = '';
      formState.value.buName = '';
      formState.value.title = '';
      userArr.value = [
        {
          userRoleId: '',
          userId: '',
          userName: '',
          effectiveFrom: moment(new Date()).format('YYYY-MM-DD'),
          email: '',
          userData: []
        }
      ];
    };
    const _visible = useModel(props, 'visible');
    watch(
      () => props.random,
      () => {
        console.log('监听ppppppppppppppppp===============', props.isQuery);
        if (props.type == 'edit' && _visible.value) {
          if (props.data.roleId) {
            API.getRoleDetail(props.data.roleId, props.businessType).then((res: any) => {
              //console.log('3333333333=', res.data, props.data.roleId)
              if (res.code === '0') {
                formState.value.approvalRole = res.data[0].name;
                roleId.value = props.data.roleId.roleId;
                formState.value.buName = res.data[0].bu;
                formState.value.flState = res.data[0].state;
                formState.value.title = res.data[0].title;
                userArr.value = res.data[0].userInfo;
                userArr.value.forEach((item: any) => {
                  console.log(item, 999999);
                  item.userData = [
                    {
                      usrnm: item.userName,
                      usrid: item.userId
                    }
                  ];
                });
              } else {
                Modal.error({
                  title: '错误提示',
                  content: res.message //res.data.message
                });
              }
            });
          }
        } else {
          setTimeout(() => {
            resetForm();
          }, 20);
        }
      }
    );
    const title = computed(() => {
      return props.type === 'add' ? 'Add New Role' : 'Edit Role';
    });

    const userSelectValue = ref<string>('');
    const effectiveFromSelectValue = ref<string>('');
    // const changeUser = (user: any) => {
    //   //userSelectValue.value = userId
    //   console.log('hello=========',user)
    //   user.userName = state.user.usrnm
    // }
    const changeEffectiveFrom = (val: Moment, timeStr: string) => {
      effectiveFromSelectValue.value = timeStr;
      console.log('userSelectValue============', val, timeStr);
    };
    const rules = reactive({
      title: [
        { required: true, message: 'Please select Title', trigger: 'blur' },
        {
          pattern: /^([a-z]|[A-Z]|[\u4e00-\u9fa5]|[0-9]|\s|_|-){0,}$/g,
          message: 'Including character which not allowed.',
          trigger: 'blur'
        }
      ],

      approvalRole: [
        { required: true, message: 'Please select Approval Role', trigger: 'blur' },
        {
          pattern: /^([a-z]|[A-Z]|[\u4e00-\u9fa5]|[0-9]|\s|_|-){0,}$/g,
          message: 'Including character which not allowed.',
          trigger: 'blur'
        }
      ],
      flState: [{ required: true, type: 'number', message: 'Please select Approval/Notitification', trigger: 'blur' }]
    });

    const handleAdd = () => {
      if (userArr.value.length === 5) {
        message.warning('you can not add user more than 4');
        return;
      }
      userArr.value.push({
        userRoleId: '',
        userId: '',
        userName: '',
        effectiveFrom: moment(new Date()).format('YYYY-MM-DD'),
        email: state.user.email,
        userData: []
      });
    };
    const handleDelete = (index: number) => {
      userArr.value.splice(index, 1);
    };
    const handleConfirm = () => {
      console.log(userArr);
      userArr.value.map((item: any) => {
        console.log('hahaha===', typeof item.effectiveFrom);
        // item.email = state.user.email
        if (!(typeof item.effectiveFrom === 'string')) item.effectiveFrom = item.effectiveFrom?.format('YYYY-MM-DD');
        item.userData = [];
      });
      console.log('userArr===', userArr.value);
      const paramsNormal = ref({
        bu: formState.value.buName,
        name: formState.value.approvalRole,
        roleType: props.businessType,
        title: formState.value.title,
        userInfo: userArr.value,
        roleId: computed(() => {
          if (props.type == 'add') {
            return '';
          } else {
            return props.data.roleId;
          }
        })
      });
      const paramsFL = ref({
        state: formState.value.flState,
        name: formState.value.approvalRole,
        roleType: props.businessType,
        title: formState.value.title,
        userInfo: userArr.value,
        roleId: computed(() => {
          if (props.type == 'add') {
            return '';
          } else {
            return props.data.roleId;
          }
        })
      });
      const flag = userArr.value.some((item) => !item.userId);
      if (flag) {
        message.error('User is required');
        return;
      }
      if (userArr.value.some((item) => !item.effectiveFrom)) {
        message.error('Effective From is required');
        return;
      }
      formRef.value
        .validate()
        .then(() => {
          console.log('formState========', formState);
          console.log('提交参数========', paramsNormal);
          if (props.businessType == 'FL' && props.type == 'add') {
            API.insertFLRole(paramsFL.value).then((res: any) => {
              console.log('接口返回值FL=====', res);
              if (res.code === '0') {
                message.success('Add success');
                context.emit('on-change');
              } else {
                Modal.error({
                  title: '错误信息',
                  content: res.message
                });
              }
            });
          }
          if (props.businessType == 'Normal' && props.type == 'add') {
            API.insertNormalRole(paramsNormal.value).then((res: any) => {
              console.log('接口返回值Normal=====111', res);
              if (res.code === '0') {
                message.success('Add success');
                context.emit('on-change');
              } else {
                Modal.error({
                  title: '错误信息',
                  content: res.message
                });
              }
            });
          }
          if (props.businessType == 'Normal' && props.type == 'edit') {
            console.log('editNormalParams======', paramsNormal);
            API.updateNormalRole(paramsNormal.value)
              .then((res: any) => {
                console.log('接口返回值Normal=====222', res.message);
                if (res.code === '0') {
                  message.success('success');
                  context.emit('on-change');
                } else {
                  Modal.error({
                    title: 'Tips',
                    content: res.message //res.data.message
                  });
                  return;
                }
              })
              .catch((error) => {
                console.log('fjdkdkdkdkdk', error);
              });
          }
          if (props.businessType == 'FL' && props.type == 'edit') {
            console.log('editFLParams======', paramsFL);
            API.updateFLRole(paramsFL.value).then((res: any) => {
              console.log('接口返回值FL=====', res);
              if (res.code === '0') {
                message.success('success');
                context.emit('on-change');
              } else {
                Modal.error({
                  title: 'Tips',
                  content: res.message //res.data.message
                });
                return;
              }
            });
          }
          //message.success("Add success");
          _visible.value = false;
        })
        .catch((error: any) => {
          console.log('error', error);
        });
    };
    const handleCancel = () => {
      _visible.value = false;
    };
    // const fillFormInEdit = () => {
    //   const data = props.data;
    //   if (props.type === "edit") {
    //     formState.value.approvalRole = data?.role as string;
    //     formState.value.buName = data?.buName as string;
    //     formState.value.title = data?.title as string;
    //     if (data?.users && data?.users.length) {
    //       userArr.value = [];
    //       data?.users.forEach((item: any) => {
    //         userArr.value.push({
    //           userRoleId: "",
    //           userName: item.user,
    //           effectiveFrom: item.effective,
    //           userId: "",
    //           email: item.email
    //         });
    //       });
    //     }
    //   }
    // };
    // 关闭弹窗清空表单
    watch(_visible, (val) => {
      if (!val) {
        resetForm();
      } else {
        // fillFormInEdit();
      }
    });
    const filterOption = (input: string, option: any) => {
      return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const disabledDate = (current: Moment) => {
      return current && current < moment().startOf('day');
    };

    const userData = ref<any[]>([]);

    const handleUserSearch = (val: string, item: any) => {
      console.log(item);
      fetch(val, (d: any[]) => (item.userData = d));
    };
    const handleUserChange = (id: string, item: any) => {
      const cur = item.userData.filter((usr: any) => {
        return usr.usrid === id;
      });
      item.userName = cur[0].usrnm;
      item.email = cur[0].email;
    };
    return {
      formRef,
      formState,
      rules,
      title,
      _visible,
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      userArr,
      handleAdd,
      handleDelete,
      handleCancel,
      handleConfirm,
      // changeUser,
      changeEffectiveFrom,
      effectiveFromSelectValue,
      userSelectValue,
      filterOption,
      disabledDate,
      userData,
      handleUserSearch,
      handleUserChange
    };
  },
  components: {
    PlusCircleOutlined,
    DeleteOutlined
  }
});
