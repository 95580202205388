
import { computed, defineComponent, ref, unref, onBeforeUnmount } from "vue";
import { message, Modal } from "ant-design-vue";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import RoleModal from "../../components/roleModal.vue";
import useStripedRowClass from "@/utils/payment/useStripedRowClass";
import useRoleSetting from "@/hooks/approvalChian/useRoleSetting";
import { DataProps } from "./type";
import {getRoleDetail} from "@/API/approvalChain";
import downloadFile from "@/utils/claim/downloadFile";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const getRowClass = useStripedRowClass<DataProps>();
    const { column, getRoleList, getFinanceAndLeasingList,roleList,isFinance,deleteRole } = useRoleSetting();
    getRoleList();
    const businessType = ref<string>('Normal');
    const isQuerylist = ref<string>('');
    const handleDelete = (role: DataProps) => {
      Modal.confirm({
        content:
          "If you delete current role, all user below this role will be deleted, do you confirm?",
        cancelText: "No",
        okText: "Yes",
        onOk() {
          console.log('999999',role)
          deleteRole(role,businessType.value)
        },
      });
    };
    const modalVisible = ref<boolean>(false);
    const modalType = ref<string>("add");
    const getRandomMath = () =>{
      const randomNum = Math.random()
      return randomNum
    }
    const handleAdd = () => {
      modalType.value = "add";
      modalVisible.value = true;
    };
    const currentRow = ref<DataProps>()
    const handleExport = () => {
      console.log('当前即将导出的roleList======',roleList.value)
      let params = {} as any
      const userRoleIds = ref<any>([])
      if(roleList.value){
        roleList.value.forEach((item: any)=>{
          if(item.userInfo){
            item.userInfo.forEach((itemm: any)=>{
              userRoleIds.value.push(itemm.userRoleId)
            })
          }
        })
      }
      console.log('导出接口的参数为：',userRoleIds.value)
      if(businessType.value === 'Normal'){
        console.log('进入Normal导出模式：')
         params = {
          url: '/apapi/RoleSetting/downloadApprovalRoleDetailExcel',
          method: 'get',
          params: {
            roleUserIds: userRoleIds.value.join(',')
          },
        };
      }
      if(businessType.value === 'FL'){
        console.log('进入FL导出模式：')
        params = {
          url: '/apapi/RoleSetting/downloadFLRoleDetailExcel',
          method: 'get',
          params: {
            roleUserIds: userRoleIds.value.join(',')
          },
        };
      }
      downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
    }
    const handleEdit = (row: DataProps) => {
      console.log('当前Record======',row,businessType.value)
      currentRow.value = unref(row)
      modalType.value = "edit";
      modalVisible.value = true;
    };
    const tabClick = (tab: string) => {
      if(tab =='1'){
        isFinance.value = false
        businessType.value = 'Normal'
        isQuerylist.value = 'query'
        getRoleList();
      }
      if(tab == '2'){
        isFinance.value = true
        businessType.value = 'FL'
        isQuerylist.value = 'query'
        getFinanceAndLeasingList();
      }
    }
    const handleChange = () => {
      console.log('=============modalType.value================',businessType.value)
      if (businessType.value === 'FL') {
        getFinanceAndLeasingList()
      } else {
        getRoleList()
      }
    }
    return {
      getRowClass,
      column,
      roleList,
      isFinance,
      handleDelete,
      handleAdd,
      handleEdit,
      modalVisible,
      modalType,
      businessType,
      currentRow,
      tabClick,
      handleExport,
      handleChange,
      getRandomMath,
      isQuerylist
    };
  },
  components: {
    TableWrapper,
    RoleModal,
  },
});
